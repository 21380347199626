@import "https://cdn.jsdelivr.net/npm/bulma@1.0.2/css/bulma.min.css";

$black: #191b1b;
$true-white: #ffffff;
$white: #fafafa;
$grey: #414141;
$light-grey: #f9f9f9;
$border-color: #dedede;
$primary: #B9005B;
$primary-hovered: #9D034F;
$gradient-main: linear-gradient(to right, $black, $primary);
$linkedin-blue: #0077B5;
$bulma-primary: #00d1b2;
$bluma-primary-dark: #169d87;
$bulma-grey-light: hsl(0, 0%, 71%);
$bulma-grey: hsl(0, 0%, 48%);
$bulma-dark: hsl(0, 0%, 21%); //#363636
$bulma-white-ter: #F5F5F5;

$layout-breakpoint-mobile: 430px;
$layout-breakpoint-tablet: 830px;

$primary-font: 'Inter', sans-serif;
$explanation-font: 'League Spartan';
$font-weight-bold: 700;


body {
    font-family: $primary-font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: greyscale;
    margin: 0;
    // background-color: $black;
    // color: $white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

footer {
    a {
        color: $bulma-grey !important;

        @media (max-width: $layout-breakpoint-mobile) {
            font-size: 12px !important;
        }

        &:hover {
            color: $bulma-primary !important;
        }
    }
}

#root {
    width: 100%;
    // background-color: $true-white;

    p {
        color: $black;
        font-family: $primary-font;

        @media (prefers-color-scheme: dark) {
            color: $white;
        }

        @media (max-width: $layout-breakpoint-mobile) {
            font-size: 14px;
        }
    }

    a {
        color: $black;

        @media (prefers-color-scheme: dark) {
            color: $white;
        }

        @media (max-width: $layout-breakpoint-mobile) {
            font-size: 14px;
        }

        &:hover {
            color: $bulma-primary;
        }
    }


    button {
        &:hover {
            p {
                color: $bulma-primary !important;
            }

            color: $bulma-primary !important;
        }
    }

    h1 {
        @media (max-width: $layout-breakpoint-mobile) {
            font-size: 30px !important;
        }
    }

    h2 {
        @media (max-width: $layout-breakpoint-mobile) {
            font-size: 20px !important;
        }
    }

    h3 {
        @media (max-width: $layout-breakpoint-mobile) {
            font-size: 18px !important;
        }
    }

    h4 {
        @media (max-width: $layout-breakpoint-mobile) {
            font-size: 16px
        }
    }

    h5 {
        @media (max-width: $layout-breakpoint-mobile) {
            font-size: 14px
        }
    }
}


// element specific stylings
.GreyHover {
    p {
        color: $grey !important;

        @media (prefers-color-scheme: dark) {
            color: $white !important;
        }

    }
}

.Hover {
    color: $black !important;

    @media (prefers-color-scheme: dark) {
        color: $bulma-primary !important;
    }

}

.Burger {
    color: $black !important;

    @media (prefers-color-scheme: dark) {
        color: $white !important;
    }
}

.Playground-side-menu {
    background-color: $bulma-white-ter !important;
    min-height: 100vh !important;
    padding: 10px 30px !important;
    min-width: 360px !important;
    width: 360px !important;
    max-width: 400px !important;

    @media (prefers-color-scheme: dark) {
        background-color: $black !important;
        border-right: $grey 0.2px solid;
    }

    @media (max-width: $layout-breakpoint-tablet) {
        padding: 10px !important;
        min-width: 270px !important;
        width: 270px !important;
        max-width: 270px !important;
    }

    @media (max-width: $layout-breakpoint-mobile) {
        min-height: 100px !important;
        min-width: 100vw !important;
        width: 100vw !important;
        max-width: 100vw !important;
    }
}


.Playground-sub-container {
    min-height: 100px !important;
    width: 100% !important;
    max-width: 100%;
    padding: 30px 10px !important;
    margin: 0 auto;
    position: relative;

    @media (max-width: $layout-breakpoint-tablet) {
        width: 100% !important;
        max-width: 100%;
        padding: 30px 10px !important;
    }

    @media (max-width: $layout-breakpoint-mobile) {
        width: 100% !important;
        max-width: 100%;
        padding: 30px 10px 10px !important;
    }
}


// universal stylings
.no-display-mobile {
    @media (max-width: $layout-breakpoint-mobile) {
        display: none;
    }
}

.no-display-tablet {
    @media (max-width: $layout-breakpoint-tablet) {
        display: none;
    }
}

.no-display-desktop {
    display: none;

    @media (max-width: $layout-breakpoint-tablet) {
        display: block;
    }
}

.no-display {
    display: none;
}


.spinner {
    border-radius: 50%;
    color: $true-white;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.spinner:before {
    border-radius: 50%;
    position: absolute;
    content: '';
    width: 10.4px;
    height: 20.4px;
    background: $primary;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
    border-radius: 50%;
    position: absolute;
    content: '';
    width: 10.4px;
    height: 10.2px;
    background: $primary;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
}

fieldset {
    border: solid 3px transparent;
    border-top-color: $border-color;
    box-sizing: border-box;
    // grid-area: 1 / 1; /* first row, first column */
    // padding: 20px;
    width: 90%;
}

legend {
    margin: auto;
    padding: 0 4px;
}


ul {
    list-style: disc outside;
}

li.is-active {
    color: $black;
    font-weight: $font-weight-bold;
    border-top-color: $border-color;

    @media (prefers-color-scheme: dark) {
        color: $white;
    }

    p {
        color: $black;
        font-weight: $font-weight-bold;

        @media (prefers-color-scheme: dark) {
            color: $white;
        }
    }
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip:hover {
    visibility: visible;
}

.tooltiptext {
    font-size: 16px;
    visibility: hidden;
    width: 300px;
    max-width: 300px;
    min-width: 300px;
    background-color: $white;
    color: $black;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;

    @media (prefers-color-scheme: dark) {
        color: $white;
        background-color: $black;
    }

    @media (max-width: $layout-breakpoint-tablet) {
        font-size: 13px;
    }

    @media (max-width: $layout-breakpoint-mobile) {
        font-size: 12px;
    }
}


.navbar-link:not(.is-arrowless)::after {
    border-color: $grey;
    margin-top: -0.375em;
    inset-inline-end: 1.125em;
}